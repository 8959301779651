// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: "http://localhost/ApiCitas/v1/",
  // baseUrl2: "http://localhost",

  //  baseUrl2: 'http://ec2-184-72-194-11.compute-1.amazonaws.com',
  //  baseUrl: 'http://ec2-184-72-194-11.compute-1.amazonaws.com/ApiCitas/v1/'
  //Maquina de Julio
  baseUrl2: "https://pruebasninetysixty.softclotech.com",
  baseUrl: "https://pruebasninetysixty.softclotech.com/ApiCitas/v1/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
